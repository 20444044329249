<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-6 blue-row">
          <div class="blue-card">
            <p>Watch this space</p>
            <p>Dashboard update on going</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Facilies",
  components: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$black: #000000;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
$fontFamily2: url("https://fonts.googleapis.com/css2?family=Gotham-Ultra:wght@100&display=swap");

// Countdowndashboard
.blue-row {
  margin: 20% auto;
}
.blue-card {
  background: blue;
  color: #ffffff;
  height: 11rem;
  border-radius: 10px;
  padding-top: 2.5rem;
}
.blue-card > p:first-child {
  font-size: 30px;
}
.blue-card > p:nth-child(2) {
  font-size: 23px;
}
// countdownboard
</style>
